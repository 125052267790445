import { Col, Row } from "antd";

export const Cabecera = () => {
  return (
    <Row className="rowHeader" style={{margin: 10}}>
      <Col span={4}>Folio</Col>
      <Col span={4}>Siniestro</Col>
      <Col span={4}>Poliza</Col>
      <Col span={4}>Estatus</Col>
    </Row>
  );
};
