export const types = {
  // Ver
  storeBackendVer: "[notificationErrAcion] storeBackendVer",

  // notify
  notification: "[notificationErrAcion] notification",

  // loginActions
  setLoginErr: "[loginActions] setLoginErr",
  setLogged: "[loginActions] setLogged",
  setMessage: "[loginActions] setMessage",
  storeUserData: "[loginActions] storeUserData",

  // Concesionartios

  setFiltroConcesionarios: "[concesionariosActions] setFiltroConcesionarios",
  storeEstatusCat: "[concesionariosActions] storeEstatusCat",
  storeOtroArreglo: "[concesionariosActions] storeOtroArreglo",
  storeConcesionariosServicios:
    "[concesionariosActions] storeConcesionariosServicios",
  storeConcesionariosServicio:
    "[concesionariosActions] storeConcesionariosServicio",
  setCargandoDetalle: "[concesionariosActions] setCargandoDetalle",

  //// PT
  storePtServicios: "[ptActions] storePtServicios",
  setFiltroPt: "[ptActions] setFiltroPt",
  storePtDetalle: "[ptActions] storePtDetalle",
  storeIncidenciasAbiertas: "[ptActions] storeIncidenciasAbiertas",
  storeListadoDocumentando: "[ptActions] storeListadoDocumentando",

  // Flujo refactura pt

  setCargando: "[flujorefacturaActions] setCargando",
  storeListaRefactura: "[flujorefacturaActions] storeListaRefactura",

  //// Historial
  storeHistorial: "[historialActions] storeHistorial",

  /// refactura
  setFiltroRefactura: "[ptActions] setFiltroRefactura",
  storeListadoRefactura: "[ptActions] storeListadoRefactura",
  storeRobos: "[robosActions] storeRobos",
  storeRoboDetalles: "[robosActions] storeRoboDetalles",
  storeHistorialRobos: "[robosActions] storeHistorialRobos",
  storeComunicacionRobos: "[robosActions] storeComunicacionRobos",
  storeComunicacionesIncial: "[robosActions] storeComunicacionesIncial",

  //adminReducer

  saveUsers: "[adminActions] saveUsers]",
  saveUser: '[adminActions] saveUser'

};
