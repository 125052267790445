import { Col, Row, Space } from "antd";
import { useSelector } from "react-redux";
import { USUARIOS_PUEDEN_ELIMINAR } from "../../constants/roles";
import { BotonEliminar } from "./BotonEliminar";
import { BotonDescargar } from "./BotonDescargar";

export const ListFile = ({ doc, onClick }) => {
  const { email } = useSelector((state) => state.userReducer.user);

  return (
    <>
      <div className="rowItemElemento">
        <Row gutter={[10, 10]} style={{ alignItems: "center" }}>
          <Col span={18}>{doc.nombreArchivo}</Col>
          <Col span={6}>
            <Space>
              <BotonDescargar onClick={onClick} doc={doc} />
              {USUARIOS_PUEDEN_ELIMINAR.includes(email) && <BotonEliminar doc={doc}/>}
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};
