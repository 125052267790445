import { Flex } from 'antd';
import CardPage from '../../../../layout/CardPage';
import { FormHistorial } from '../FormHistorial';
import { TimeLineRobos } from '../TimeLineRobos';
import { FormComunication } from '../FormComunication';
import { TimeLineComunicacion } from '../steps/TimeLineComunicacion';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { post } from '../../../../utils/fetch';
import { BASE_URL_ROBOS } from '../../../../constants/url';
import { getComunicaciones } from '../../../../context/actions/robosActions';
import { useEffect } from 'react';


export const FooterDetalles = () => {


  const motivos = [
    { label: 'Número fuera de servicio', value: 'numero fuera de servicio' },
    {label: 'Número incorrecto', value: 'numero incorrecto'},
    {label: 'Notificación de la localización', value: 'notificacion de la localizacion'},
    {label: 'Notificación y compartir recuperador', value: 'notificación y compartir recuperador'},
    {label: 'Vehículo en mal estado', value: 'vehículo en mal estado'},
    {label: 'Contacto con recuperador', value: 'contacto con recuperador'},
  ]
  const {id} = useParams()
  const comuLoc = useSelector(state => state.robosReducer.comunicacionRobos)
  const dispatch = useDispatch()
  useEffect(() => {
    void dispatch(getComunicaciones(id, 'localizacion'))
  }, []);
  const onFinish = async (values) => {
    values.id = +id
    values.tipo = 'localizacion'
    const result = await post(`${BASE_URL_ROBOS}/postComunicacion`, values)
    void dispatch(getComunicaciones(id, 'localizacion'))
  }
  return (
    <>

      <Flex gap={10} justify="space-between">

        <CardPage titulo={'Historial'} styles={{width: '60%', scroll: 'auto'}}>
          <FormHistorial/>
          <TimeLineRobos/>
        </CardPage>

        <CardPage titulo={'Comunicación'} styles={{width: '50%'}}>
          <FormComunication motivos={motivos} onFinish={onFinish} title={'Agregar comunicación localización'}
                            styleTitle={{marginBottom: '20px'}} styleForm={{padding: '20px'}}/>
          <TimeLineComunicacion tipo={'localizacion'} style={{maxHeight: '400px', overflow: 'auto'}} list={comuLoc}/>
        </CardPage>


      </Flex>
    </>
  )
}