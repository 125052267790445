import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { UltimoContacto } from "./_UltimoContacto";

export const Item = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Row
      onClick={() => navigate(`/PTDetalle/${item.id}`)}
      gutter={[10, 10]}
      className="rowItemElemento"
      style={{ cursor: "pointer" }}
    >
      <Col xs={8} lg={6} xl={2}>
        {item.idUnico}
      </Col>
      <Col xs={8} lg={6} xl={3}>
        <UltimoContacto item={item} />
      </Col>
      <Col xs={8} lg={6} xl={4}>
        {item.numSiniestro}
      </Col>
      <Col xs={8} lg={6} xl={4}>
        {item.marca}
      </Col>
      <Col xs={8} lg={6} xl={4}>
        {item.tipo}
      </Col>
      <Col xs={8} lg={6} xl={3}>
        {item.modelo}
      </Col>
      <Col xs={24} lg={12} xl={4}>
        {item.numSerie}
      </Col>
    </Row>
  );
};
