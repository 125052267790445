import {
    URL_PT_DOCUMENTOS,
  } from "../../constants/url";
  import {
    composeErrorNotification,
    composeSuccessNotification,
  } from "../../utils/composeNotification";
  import { get, post, postGetFile } from "../../utils/fetch";
  import { types } from "../types/types";

  import { notification } from "./notificationActions";
import { startGetPTServicio } from "./ptActions";


export const startEliminaDocumento = (id, PtId) => {
  return async (dispatch, getState) => {
    const url = `${URL_PT_DOCUMENTOS}/EliminaDocumento/${id}`;
    const result = await get(url);
    if (result.code === 0) {
      dispatch(startGetPTServicio(PtId));
      dispatch(notification(composeSuccessNotification("Documento eliminado")));
    } else {
      dispatch(
        notification(
          composeErrorNotification("DeleteDocumento", `${result.error}`)
        )
      );
    }
  };
}