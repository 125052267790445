import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetListadoDocumentando } from "../../../context/actions/ptActions";

export const useGetListadoDocumentado = () => {
  const { listadoDocumentando } = useSelector((state) => state.ptReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetListadoDocumentando());
  }, [dispatch]);

  return { listadoDocumentando };
};
