import { CloudDownloadOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

export const BotonDescargar = ({onClick, doc}) => {
  return (
   
    <Tooltip title={"Descargar Documento"}>
    <CloudDownloadOutlined
      style={{ fontSize: "18px", color: "#1890ff" }}
      className=""
      onClick={() => onClick(doc)}
    />
  </Tooltip>
  )
}
