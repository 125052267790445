import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { startEliminaDocumento } from "../../context/actions/ptDocumentosActions";
import { useParams } from "react-router";

export const BotonEliminar = ({ doc }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleClick = () => dispatch(startEliminaDocumento(doc.id, id));

  return (
    <Tooltip title="Eliminar Archivo">
      <Popconfirm
        placement="top"
        title="Eliminación de Archvo"
        description="¿Estás seguro de eliminar este archivo?"
        okText="Si"
        cancelText="No"
        onConfirm={handleClick}
      >
        <DeleteOutlined
         
          className="botonIcono"
          style={{ color: "orange", fontSize: 18 }}
        />
      </Popconfirm>
    </Tooltip>
  );
};
