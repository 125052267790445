import React from "react";
import { dateFromString } from "../../../utils/dateFromString";
import { Col, Row } from "antd";

export const UltimoContacto = ({ item }) => {
  let color = "bolaVerde";
  if (item.diasUltimoContacto > 1) color = "bolaNaranja";
  if (item.diasUltimoContacto > 3) color = "bolaRoja";

  return (
    <Row>
      <Col>
        <div className={`bola ${color}`}>{item.diasUltimoContacto}</div>
      </Col>
      <Col> {dateFromString(item.ultimoContacto).soloFecha}</Col>
    </Row>
  );
};
