import { Col, Row } from "antd";
import React from "react";
import { Elementos } from "./_Elementos";
import { useSelector } from "react-redux";


export const Tabla = () => {
  const { incidenciasAbiertas } = useSelector((state) => state.ptReducer);
  return (
    <div style={{ margin: 20 }}>
      <Row className="rowHeader">
        <Col span={4}>
          Folio
        </Col>
        <Col span={4} >
          Siniestro
        </Col>
        <Col span={4} >
          Poliza
        </Col>
        <Col span={4} >
          Fecha
        </Col>
        <Col span={4} >
          Incidencia
        </Col>
      </Row>

      {incidenciasAbiertas.map((a, i) => (
        <Elementos incidencia={a} key={i} />
      ))}

    </div>
  );
};
