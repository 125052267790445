import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListFile } from "../../../../components/listas/ListFile";
import { URL_PT } from "../../../../constants/url";
import { startDownloadFile } from "../../../../context/actions/genericoActions";

export const Listado = () => {
  const dispatch = useDispatch();
  const { documentos } = useSelector((state) => state.ptReducer.ptDetalle);

  const handleClick = (doc) => {
    const url = `${URL_PT}/GetDocumento/${doc.id}`;
    dispatch(startDownloadFile(url, doc.nombreArchivo));
  };

  return (
    <div>
      {documentos.map((d, i) => (
        <ListFile key={i} doc={d} onClick={handleClick} />
      ))}
    </div>
  );
};
