import React from "react";
import CardPage from "../../../layout/CardPage";
import { useGetListadoDocumentado } from "./useGetListadoDocumentado";
import { Item } from "./_Item";
import { Col, Row } from "antd";

export const Documentando = () => {
  const { listadoDocumentando } = useGetListadoDocumentado();
  return (
    <CardPage titulo="Documentando">
      <Row className="rowHeader">
        <Col xs={8} lg={6} xl={2}>
          ID
        </Col>
        <Col xs={8} lg={6} xl={3}>
          Ultimo Conatto
        </Col>
        <Col xs={8} lg={6} xl={4}>
          Siniestro
        </Col>
        <Col xs={8} lg={6} xl={4}>
          Marca
        </Col>
        <Col xs={8} lg={6} xl={4}>
          Tipo
        </Col>
        <Col xs={8} lg={6} xl={3}>
          Moodelo
        </Col>
        <Col xs={24} lg={12} xl={4}>
          Serie
        </Col>
      </Row>

      {listadoDocumentando.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </CardPage>
  );
};
