export const useGetFielsServicioPT = () => {
  const fields = [
    {
      label: "Notificación por whatsapp a asegurado",
      name: "fechaEnvioWhatsApp",
      type: "datePicker",
    },

    {
      label: "Inicio doc. después de notificación whatsapp",
      name: "fechaRecepcionPrimerDocumento",
      type: "datePicker",
    },
    {
      label: "Fecha contacto nuevos datos",
      name: "fechaContactacionConNuevosDatos",
      type: "datePicker",
    },
    { label: "WhatsApp enviado al Numero", name: "numeroEnvioWhatsApp" },
    // {label: 'Fecha respuesta WhatsApp', name: 'fechaRespuestaWhatsApp', type: 'datePicker'},

    {
      label: "Notificación por correo a asegurado",
      name: "fechaEnvioCorreo",
      type: "datePicker",
    },
    {
      label: "Inicio doc. después de notificación correo",
      name: "fechaRespuestaCorreo",
      type: "datePicker",
    },
    {
      label: "Enviado a la dirección de correo",
      name: "direccionEmailEnvioCorreo",
    },

    {
      label: "Fecha de paro de indicador",
      name: "fechaParoIndicador",
      type: "datePicker",
    },
    {
      label: "Fecha de reactivación de indicador",
      name: "fechaReactivacion",
      type: "datePicker",
    },
  ];
  return { fields };
};
